<script>
import Master from './components/master.vue'
import Choose from './components/choose.vue'
import Feature from './components/feature.vue'
export default {
  components: {
    Master,
    Choose,
    Feature
  }
}
</script>

<template>
    <div class="gemini">
        <Master></Master>
        <Choose></Choose>
        <Feature></Feature>
    </div>
</template>

<style scoped>
.gemini {
  display: flex;
  flex-direction: column;
}
</style>
