<template>
  <div class="product">
    <div class="hidden-anchor"></div>
    <section class="banner">
      <hgroup>
        <h1>OrionX计算平台</h1>
        <h3>GPU资源池化解决方案，软件定义算力，支持云端和数据中心部署，帮助客户提高AI资源利用率和降低TCO，提高算法工程师的工作效率。</h3>
      </hgroup>
      <a :href="`${publicPath}product.pdf`" class="product-btn" target="_blank">产品手册</a>
    </section>
    <section v-if="videoList && videoList.length > 0" style="display: flex; flex-direction: column; align-items: center;padding: 0 15px;">
      <div class="space-header">
        视频介绍
      </div>
      <video-space :videoList="videoList" ></video-space>
    </section>
    <section style="display: flex; flex-direction: column; align-items: center;">
      <div class="space-header">
        产品商务意向
      </div>
      <div style="height: 48px; font-size: 14px; font-family: PingFangSC-Medium, PingFang SC; font-weight: 500; color: #666666;">
        选择与我们同行，成为AI计算普惠价值的受益者
      </div>
      <div class="form" style="width: 100%;padding: 0 15px;">
        <!-- <van-form>
          <van-field
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form> -->
        <a-form-model layout="vertical" ref="form" :model="formModel" :rules="formRule" >
          <a-form-model-item label="客户称呼" :max-length="10" prop="appellation">
            <a-input v-model="formModel.appellation" placeholder="如何称呼您" :max-length="10" class="form-item" />
          </a-form-model-item>
          <a-form-model-item label="所属公司" prop="companyName">
            <a-input v-model="formModel.companyName" placeholder="请输入您供职的公司" :max-length="50" class="form-item" />
          </a-form-model-item>
          <a-form-model-item label="职位名称" prop="position">
            <a-input v-model="formModel.position" placeholder="请输入您的职位" :max-length="20" class="form-item" />
          </a-form-model-item>
          <a-form-model-item label="所属部门" prop="department">
            <a-input v-model="formModel.department" placeholder="请输入您所在部门" :max-length="20" class="form-item" />
          </a-form-model-item>
          <a-form-model-item label="联系方式" prop="contact" class="phone">
            <div>
              <a-input v-model="formModel.contact.phoneNumber" placeholder="请输入单位电话或个人手机号" :max-length="20" class="form-item" />
              <a-input v-model="formModel.contact.email" placeholder="请输入您的电子邮箱" :max-length="50" class="form-item mt20" />
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item prop="email" class="email">
            <div slot="label" style="display: none;"></div>
            <a-input v-model="formModel.email" placeholder="请输入您的电子邮箱" :max-length="50" style="width: 100%; height: 36px;" />
          </a-form-model-item> -->
        </a-form-model>
        <div style="height: 50px; display: flex; align-items: center;">
          <span class="liu_yan-label">商务意向留言：</span>
        </div>
        <div style="position: relative;">
          <a-textarea v-model="formModel.message" :max-length="100" placeholder="请输入您关于试用、购买产品或其他商业意愿的具体内容" class="liu_yan-textarea" />
          <div style="right:2vw; bottom: 3vw; position:absolute; font-size: 12px; color: #999;">
            已输入{{ formModel.message.length || 0 }}/100
          </div>
        </div>
        <div style="display: flex; justify-content: center;">
          <a-button
            class="submit-btn"
            @click="handleIntentions"
            :loading="applyLoading"
          >
            提交商业意向信息
          </a-button>
        </div>
      </div>
    </section>
    <!-- <section class="download" ref="download">
      <common-title :title="'下载安装流程'" subTitle="Download and install process"></common-title>
      <ol class="process-icons">
        <li v-for="item of iconArr" :class="`icon-${item}`" :key="item"></li>
      </ol>
      <div class="process-text">
        <div class="text-item">
          <h5>1、下载产品</h5>
          <p>点击<a @click.prevent="handleDownload">“下载产品”</a>
            获取产品安装包；</p>
        </div>
        <div class="text-item">
          <h5>2、申请试用/申请购买</h5>
          <p class="detail">在部署和使用产品前，您需要首先<a @click.prevent="goAnchor(`trial`)">“申请试用”</a>或者<a @click.prevent="goAnchor(`purchase`)">“申请购买”</a></p>
        </div>
        <div class="text-item">
          <h5>3、获取激活文件</h5>
          <p class="detail">对于成功提交试用申请或者已购买产品的用户，在部署和使用产品前，请确保您已经获得有效的激活文件。激活文件可以在<router-link to="/user/index">“个人中心”</router-link>-> “订单管理” -> “获取激活文件”处下载</p>
        </div>
        <div class="text-item">
          <h5>4、配置部署</h5>
          <p class="detail">查看<a :href="deployDocUrl" target="_blank">“部署文档”</a>了解如何部署产品</p>
        </div>
      </div>
      <a class="product-btn" @click.prevent="handleDownload">下载产品</a>
    </section>
    <section class="purchase">
      <common-title :title="'试用与购买'" subTitle="Trial and purchase"></common-title>
      <ol class="purchase-list">
        <li v-for="pro of proPurchase"
            :key="pro.type"
            class="van-hairline--surround"
            :ref="pro.type">
          <img :src="pro.icon" class="pro-icon">
          <div class="pro-content">
            <h3>{{pro.title}}</h3>
            <div class="item_box">
              <p v-for="(item,index) of pro.items" :key="index">{{item}}</p>
            </div>
            <a class="product-btn" @click.prevent="handlePurchaseClick(pro)">{{pro.btnText}}</a>
          </div>
        </li>
      </ol>
    </section>
    <van-dialog v-model="modalShow"
                title=""
                :beforeClose="verifyCode"
                show-cancel-button
    >
      <div class="invicode-box">
        <van-field
          v-model="invitationCode"
          name="邀请码"
          clearable
          label=""
          placeholder="请输入邀请码"
          :rules="[{ required: true,pattern: regInviCode, message: '请输入正确的邀请码' }]"
        ></van-field>
      </div>
    </van-dialog> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { exceptionMap, deployDocUrl, downloadProUrl, GPU_CONFIG, publicPath } from '@/utils/variable'
import { regInviCode, regComplexPhone } from '@/utils/regexp'
import pageMixin from '@/mixins/pageMixin'
// import commonTitle from '@/components/commonTitle'
import { Toast } from 'vant'
import VideoSpace from '@/components/videoSpace.vue'
const proPurchase = [
  {
    title: '免费试用',
    icon: require('@/assets/images/product/try_img.png'),
    type: 'trial',
    btnText: '申请试用',
    offerId: 1,
    successText: '您的试用申请已提交，请耐心等待，我们的工作人员会尽快与您联系。',
    okText: '确定',
    items: [
      '每个手机号码试用机会一次；',
      `有效期${GPU_CONFIG.exp}个月，自工作人员审核通过之日起开始计时；`,
      '提交试用申请后，工作人员会尽快与您联系。'
    ],
    okCancel: true,
    handler: 'offerPost'
  },
  {
    title: '产品购买',
    icon: require('@/assets/images/product/purchase_img.png'),
    type: 'purchase',
    btnText: '申请购买',
    successText: '您的购买申请已提交，请耐心等待，我们的工作人员会尽快与您联系。',
    okText: '确定',
    offerId: 2,
    items: [
      '按年付费，线下支付；',
      '提交购买申请后，工作人员会尽快与您联系。'
    ],
    okCancel: true,
    handler: 'offerPost'
  },
  {
    title: '邀请码试用',
    icon: require('@/assets/images/product/invitationcode_img.png'),
    type: 'code',
    btnText: '使用邀请码',
    successText: '邀请码激活成功！',
    okText: '下载激活文件',
    cancelText: '查看部署文档',
    offerId: 3,
    items: [
      '更灵活的试用上限；',
      '仅针对拥有邀请码的用户。'
    ],
    handler: 'showCodeModel'
  }
]

export default {
  mixins: [pageMixin],
  components: {
    VideoSpace
  },
  data () {
    return {
      applyLoading: false,
      formModel: {
        appellation: '',
        companyName: '',
        position: '',
        department: '',
        contact: {
          phoneNumber: '',
          email: ''
        },
        message: ''
      },
      formRule: {
        appellation: { required: true, message: '必填项' },
        companyName: { required: true, message: '必填项' },
        contact: { required: true, validator: this.validate }
        // position: { required: true, message: '请输入用户邮箱' },
        // department: { required: true, message: '请输入用户邮箱' },
      },
      invitationCode: '',
      exceptionMap,
      proPurchase,
      deployDocUrl,
      downloadProUrl,
      regInviCode,
      publicPath,
      iconArr: ['download', 'purchase', 'activation', 'dispose'],
      modalShow: false,
      headerHeight: 0,
      loading: false,
      videoList: []
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  watch: {
    '$route' () {
      this.handlePosChange()
    }
  },
  mounted () {
    this.headerHeight = document.getElementsByTagName('header')[0].offsetHeight
    this.handlePosChange()
    this.fetchVideoList()
  },
  methods: {
    async fetchVideoList () {
      const videoList = await this.api.graphqlApi(`
        query ProductVideos {
          productVideo(status: PUBLISHED, orderBy: {createdUtc: DESC}) {
            contentItemId
            createdUtc
            description
            modifiedUtc
            publishedUtc
            displayText
            title
            video {
              urls
            }
            thumbnail {
              urls
            }
          }
        }
      `).catch(e => { return { productVideo: [] } })
      this.videoList = (videoList.productVideo || []).map(v => {
        return {
          title: v.displayText,
          description: v.description,
          poster: v.thumbnail.urls[0] ? v.thumbnail.urls[0] : '',
          video: v.video.urls[0] ? v.video.urls[0] : ''
        }
      })
    },
    async handleIntentions () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      const params = {
        appellation: this.formModel.appellation,
        companyName: this.formModel.companyName,
        position: this.formModel.position,
        department: this.formModel.department,
        phoneNumber: this.formModel.contact.phoneNumber,
        email: this.formModel.contact.email,
        message: ''
      }
      this.applyLoading = true
      this.api.purchaseintentions(params).then(res => {
        this.applyLoading = false
        Toast.success('感谢！您的意向信息已成功提交至产品管理人员。核实信息后我们会尽快联系您。')
        this.formModel = {
          appellation: '',
          companyName: '',
          position: '',
          department: '',
          contact: {
            phoneNumber: '',
            email: ''
          },
          message: ''
        }
      }).catch(e => {
        this.applyLoading = false
        Toast.fail('填写内容不符合规范，请重新填写后提交')
        // console.log(e)
      })
    },
    validate (rule, value, callback) {
      if (value.phoneNumber === '' && value.email === '') {
        callback(new Error('至少填写一项'))
      } else if (value.phoneNumber) {
        if (regComplexPhone.test(value.phoneNumber.toString().replace(/\s/g, ''))) {
          callback()
        } else {
          callback(new Error('请输入正确的电话号码'))
        }
      } else {
        callback()
      }
    },
    goAnchor (name) {
      setTimeout(() => {
        try {
          if (this.$refs[name]) {
            if (name === 'download') {
              document.body.scrollTop = document.documentElement.scrollTop = this.$refs[name].offsetTop - this.headerHeight
            } else {
              document.body.scrollTop = document.documentElement.scrollTop = this.$refs[name][0].offsetTop - this.headerHeight
            }
          }
        } catch (e) {
          console.log(e)
        }
      }, 100)
    },
    handlePosChange () {
      this.$route.query && this.goAnchor(this.$route.query.pos)
    },
    handleDownload () {
      const ua = navigator.userAgent
      const isWeixin = !!/MicroMessenger/i.test(ua)
      if (isWeixin) {
        // this.$toast('微信不支持下载，\n请选择在浏览器或Safari中打开')
        this.openModal({
          title: '微信不支持下载，\n请选择在浏览器或Safari中打开',
          okCancel: true,
          ok: '知道了'
        })
        return false
      }
      if (!this.token) {
        this.confirmLogin()
        return false
      } else {
        this.openModal({
          title: '产品安装包文件较大(>500M)，\n确定下载？'
        }, () => {
          window.open(downloadProUrl)
        })
      }
    },
    confirmLogin () {
      this.$dialog.confirm({
        title: '',
        message: '为方便我们更好的为您服务，\n请先进行登录。'
      }).then(() => {
        // on confirm
        setTimeout(() => {
          this.$router.push({
            path: '/account/signin',
            query: {
              redirect: this.$route.fullPath
            }
          })
        }, 200)
      }).catch(() => {
      })
    },
    showCodeModel () {
      this.invitationCode = ''
      this.modalShow = true
    },
    handlePurchaseClick (pro) {
      const { handler } = pro

      if (this.loading) return false
      if (!this.token) {
        this.confirmLogin()
        return false
      }
      this[handler](pro)
    },
    openModal (config, cb1, cb2) {
      this.$dialog.confirm({
        message: config.title,
        cancelButtonText: config.cancel ? config.cancel : '取消',
        confirmButtonText: config.ok ? config.ok : '确定',
        showCancelButton: !config.okCancel
      }).then(() => {
        typeof cb1 === 'function' && cb1()
      }).catch(() => {
        typeof cb2 === 'function' && cb2()
      })
    },
    async offerPost (pro, done) {
      this.loading = true
      const { offerId, successText, okText, cancelText, type, okCancel } = pro
      const params = { offerId: offerId }
      if (type === 'code') {
        params.invitationCode = this.invitationCode
      }
      this.api.offersPost(params).then(async data => {
        this.openModal({
          title: successText,
          ok: okText,
          cancel: cancelText,
          okCancel
        }, () => {
          if (type === 'code') {
            // 下载激活文件
            this.$router.push('/user/index')
          }
        }, () => {
          if (type === 'code') {
            // 查看部署文档
            window.open(deployDocUrl, '_blank')
          }
        })
        this.modalShow = false
        done && done()
      }).catch(error => {
        const title = exceptionMap[error]
        let config = {
          title: title
        }
        done && done(false)
        if (error === -18) {
          if (type === 'trial') {
            config.title = config.title.replace('购买', '试用')
          }
          config = Object.assign({ ok: '去查看', cancel: '知道了' }, config)
          this.openModal(config, () => {
            this.$router.push('/user/index')
          })
        } else {
          this.$toast.fail(title)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    verifyCode (action, done) {
      if (action === 'confirm') {
        if (!this.regInviCode.test(this.invitationCode)) {
          this.$toast.fail('请输入正确的邀请码')
          done(false)
          return
        }
        this.offerPost(this.proPurchase[2], done)
      } else if (action === 'cancel') {
        done()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .product{
    .space-header {
      height: 104px;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 104px;
      margin-top: 12px;
    }
    .product-btn{
      .radius_button;
      margin: 50px auto 0;
      overflow: hidden;
      color: #fff;
    }
    .hidden-anchor{
      height: 0;
      position: relative;
      top: -@header-height;
    }
    .banner{
      font-family: PingFangSC-Regular, PingFang SC;
      .flex(@direction: column);
      width: 100%;
      height: 600px;
      background-image: url('~@/assets/images/product/banner.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      color: #fff;
      text-align: left;
      margin-top: -@header-height;
      hgroup{
        max-width: 650px;
        margin: @header-height auto 0;
        font-weight: 600;
      }
      .btn {
        background: rgba(255,255,255, 0.1);
        border: 1px solid #fff;
        margin-left: 10px;
      }
      h1{
        font-size: 72px;
        font-weight: 600;
        text-align: center;
        line-height: 100px;
      }
      h2{
        font-size: 40px;
        margin: 20px 0 34px;
      }
      h3{
        font-size: 28px;
        line-height: 44px;
        margin-top: 32px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
      }
    }
    .download{
      padding: 50px;
      background: #E8F2FF;
      .process-icons{
        .flex(@justify: flex-start);
        margin: 56px auto 58px;
        li{
          position: relative;
          width: 93px;
          height: 93px;
          .full_bg;
          &:not(:last-child){
            margin-right: 90px;
            &::after{
              position: absolute;
              left: 98px;
              top: 50%;
              content: '';
              display: block;
              width: 80px;
              height: 2px;
              margin-top: -2px;
              border-bottom: 2px rgba(36,54,89,.45) dashed;
            }
          }
          &.icon-download{
            background-image: url("~@/assets/images/product/download_icon.png");
          }
          &.icon-purchase{
            background-image: url("~@/assets/images/product/purchase_icon.png");
          }
          &.icon-activation{
            background-image: url("~@/assets/images/product/activation_icon.png");
          }
          &.icon-dispose{
            background-image: url("~@/assets/images/product/dispose_icon.png");
          }
        }
      }
      .process-text{
        color: @grey;
        font-size: 26px;
        .text-item{
          margin-bottom: 50px;
        }
        p{
          line-height: 36px;
        }
        h5{
          color: @black;
          font-size: 30px;
          margin-bottom: 20px;
        }
        a{
          color: @blue;
        }
      }
    }
    .purchase{
      padding: 58px 50px 50px;
      .purchase-list{
        margin-top: 50px;
        li{
          .flex(@direction: column);
          padding: 50px 45px;
          margin-bottom: 40px;
          border-radius: 5px;
          text-align: center;
          color: @grey;
          .pro-icon{
            width: 230px;
            height: 230px;
          }
          .pro-content{
            h3{
              margin: 40px 0 26px;
              color: @black;
              font-size: 32px;
              font-weight: 600;
            }
            p{
              font-size: 28px;
              line-height: 50px;
            }
          }
        }
      }
    }
    .invicode-box{
      padding: 0 30px 30px;
    }

    .form-item {
      height: 80px;
      width: 100%;
      font-size: 28px;
      padding: 0 24px;
    }
    .mt20 {
      margin-top: 40px;
    }
    .liu_yan-label {
      color: rgba(0, 0, 0, 0.85);
      font-size: 30px;
    }
    .liu_yan-textarea {
      font-size: 28px;
      height: 240px;
    }
    .submit-btn {
      width: 400px;
      height: 80px;
      background: #0083ff;
      border-radius: 50px;
      margin-top: 40px;
      margin-bottom: 60px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      cursor: pointer;
    }
  }
</style>
<style lang="less">
.product {
  .ant-col.ant-form-item-label{
    label {
      color: rgba(0, 0, 0, 0.85);
      font-size: 30px;
    }
    label.ant-form-item-required::before {
      color: rgba(255, 77, 79, 1);
      font-size: 30px;
    }
    &::after {
      color: rgba(0, 0, 0, 0.85);
      font-size: 30px;
    }
  }
  .ant-form-explain {
    font-size: 24px !important;
  }

}
</style>
