const client = process.client
// 官网域名  virtaicloud.com / www.virtaicloud.com / portal-test3.virtaicloud.com

// 获取百度云bos地址
export const handleAssetsUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    const assetsUrl = 'https://gemini-static.bd.bcebos.com/portal/test'
    return assetsUrl
  } else {
    if (client) {
      const assetsUrl = window.ASSETSURL
      return assetsUrl
    }
    return 'https://gemini-static.bd.bcebos.com/portal/prod'
  }
}

export function getQueryValue (queryName) {
  if (process.client) {
    const reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)', 'i')
    const r = window.location.search.substring(1).match(reg)
    if (r != null) {
      return decodeURI(r[2])
    } else {
      return null
    }
  }
}
