<template>
    <div class="feature-mobile-module">
      <div
        v-for="(detail, detailIndex) in mobileDetailList"
        :key="detailIndex"
        class="feature-mobile-module-card"
      >
        <h2 class="feature-mobile-module-card-title">{{ detail.title }}</h2>
        <img
          class="feature-mobile-module-card-img"
          :src="assetsUrl + detail.img"
          :alt="detail.title"
          loading="lazy"
          decoding="async"
        />
        <div class="feature-mobile-module-card-content">
          <div class="question">{{ detail.question }}</div>
          <div class="answer">
            <span class="highlight">{{ detail.highlight }}</span>{{ detail.answer }}
          </div>
          <div class="description">
            {{ detail.description }}
          </div>
          <ul class="detail">
            <li
              v-for="(li, liIndex) in detail.liList"
              :key="liIndex"
              v-html="li"
            ></li>
          </ul>
        </div>
      </div>
    </div>
  </template>

<script>
import { handleAssetsUrl } from '@/utils/common.js'
export default {
  name: 'FeatureMobile',
  data () {
    return {
      assetsUrl: 'https://gemini-static.bd.bcebos.com/portal/prod',
      mobileDetailList: [
        {
          title: '开发训练',
          img: '/img/home/feature/mobile/img_开发训练.webp',
          question: '文件管理维护、环境搭建费时费力？',
          highlight: '项目制，',
          answer:
              '组织开发过程，可复现、易迭代--开发环境开箱即用，支持分布式训练',
          description:
              '以项目组织开发过程所需的全部内容，免去文件管理的时间支出',
          liList: [
            '支持分布式训练框架，提速大模型训练过程，缩短项目周期',
            '支持一键克隆历史任务，助力实现快速的实验迭代',
            '支持Tensorboard可视化，灵活组合、比对分析多组实验结果，发现更多数据背后的Insight',
            '代码、数据、镜像及模型严格的版本管理，保证每一次实验的可复现性'
          ]
        },
        {
          title: '团队协作',
          img: '/img/home/feature/mobile/img_团队协作.webp',
          question: '团队协作没有合适的工具？开发资产及算力资源管理困难？',
          highlight: '工作空间，',
          answer: '整合团队协作--团队成员共享资产、成果及算力资源',
          description:
              '以工作空间整合开发团队成员、数据资产、任务成果、算力资源',
          liList: [
            '支持通过设置<span style="color: #4c7dff;">公开性</span>，将项目及数据资产共享给团队成员',
            '支持空间拥有者管理团队成员、项目、数据及资源使用权限'
          ]
        }
      ]
    }
  },
  mounted () {
    this.assetsUrl = handleAssetsUrl()
  }
}
</script>

  <style scoped lang="less">
  .feature-mobile-module {
    display: none;
  }

  @media (max-width: 780px) {
    @vv: 7.5vw;

    .feature-mobile-module {
      display: flex;
      flex-direction: column;
      padding: 60 / @vv 40 / @vv 80 / @vv;
      background: #d2e1fd;

      &-card {
        display: flex;
        width: 670 / @vv;
        flex-direction: column;
        align-items: center;
        padding: 40 / @vv;
        border: 4 / @vv solid #e3ecff;
        margin-bottom: 40 / @vv;
        background: #f0f5fe;
        border-radius: 20 / @vv;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-title {
          color: #333;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-size: 32 / @vv;
          font-weight: 600;
        }

        &-img {
          width: 515 / @vv;
          height: 380 / @vv;
          margin-top: 38 / @vv;
        }

        &-content {
          padding-top: 32 / @vv;

          .question {
            color: #828ba3;
            font-size: 20 / @vv;
          }

          .answer {
            margin-top: 16 / @vv;
            margin-bottom: 40 / @vv;
            color: #333;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-size: 24 / @vv;
            font-weight: 600;

            .highlight {
              color: #4c7dff;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-size: 24 / @vv;
              font-weight: 600;
            }
          }

          .description {
            margin-bottom: 14 / @vv;
            color: #666;
            font-family: PingFangSC-Medium, PingFang SC;
            font-size: 20 / @vv;
            font-weight: 500;
          }

          .detail {
            padding-left: 22 / @vv;
            color: #999;
            font-size: 20 / @vv;
            list-style: disc;
          }
        }
      }
    }
  }
  </style>
