<template>
  <div
    id="Home"
    class="px-10 banner"
    :class="assetsUrlTest ? 'banner-test-url' : ''"
  >
    <div class="describe title">
      <h2 class="font-semibold font-[PingFangSC-Semibold] title-center">
        Gemini AI训练平台
      </h2>
      <div class="text-[#666] title-bottom">
        结合独家OrionX算力池化能力的AI开发训练平台，提高计算集群资源利用率及运维效率。
      </div>
      <div class="hidden sl:block">
        <a-button type="primary" class="btn">
          立即开始
        </a-button>
      </div>
      <div class="banner-top" />
      <div class="banner-earth" />
    </div>
    <div class="banner-link" />
  </div>
</template>

<script>
import { handleAssetsUrl } from '@/utils/common.js'
export default {
  name: 'Master',
  data () {
    return {
      assetsUrlTest: false
    }
  },
  mounted () {
    this.assetsUrlTest = handleAssetsUrl().includes('/portal/test')
  }
}
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  overflow: hidden;
  height: 600px;
  background: linear-gradient(180deg, #dae3f2 0%, #e4eaf6 100%);

  &-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 1920px;
    margin: 0 auto;
    background:
      url("https://gemini-static.bd.bcebos.com/portal/prod/img/home/banner/img_banner_link.webp")
      no-repeat;
    background-size: 100% 100%;
  }

  &-top {
    position: absolute;
    z-index: -1;
    top: 0;
    left: -36%;
    width: 1034px;
    height: 468px;
    background:
      url("https://gemini-static.bd.bcebos.com/portal/prod/img/home/banner/img_banner_top.webp")
      no-repeat;
    background-size: 100% 100%;
  }

  &-earth {
    position: absolute;
    z-index: 2;
    right: -16%;
    bottom: 0;
    width: 760px;
    height: 340px;
    background:
      url("https://gemini-static.bd.bcebos.com/portal/prod/img/home/banner/img_banner_earth.webp")
      no-repeat;
    background-size: 100% 100%;
  }

  .describe {
    position: relative;
    z-index: 3;
    max-width: 1200px;
    height: 100%;
    padding-top: 170px;
    margin: 0 auto;
    color: #333;

    .title-top {
      font-size: 30px;
    }

    .title-center {
      font-size: 56px;
    }

    .title-bottom {
      max-width: 650px;
      margin: 32px 0 60px;
      font-size: 20px;
    }

    .btn {
      width: 156px;
      height: 48px;
      border: none;
      background: #4c7dff;
      border-radius: 4px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.banner-test-url {
  .banner {
    &-link {
      background:
        url("https://gemini-static.bd.bcebos.com/portal/test/img/home/banner/img_banner_link.webp")
        no-repeat;
      background-size: 100% 100%;
    }

    &-top {
      background:
        url("https://gemini-static.bd.bcebos.com/portal/test/img/home/banner/img_banner_top.webp")
        no-repeat;
      background-size: 100% 100%;
    }

    &-earth {
      background:
        url("https://gemini-static.bd.bcebos.com/portal/test/img/home/banner/img_banner_earth.webp")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}

@media (max-width: 1920px) {
  @lg: 19.2vw;

  .banner {
    height: 600 / @lg;

    &-link {
      max-width: 1920 / @lg;
    }

    .describe {
      padding-top: 170 / @lg;

      .title-top {
        font-size: 30 / @lg;
      }

      .title-center {
        font-size: 56 / @lg;
      }

      .title-bottom {
        max-width: 650 / @lg;
        margin: 32 / @lg 0 60 / @lg;
        font-size: 20 / @lg;
      }

      .btn {
        width: 156 / @lg;
        height: 48 / @lg;
        border-radius: 4 / @lg;
        font-size: 20 / @lg;
      }
    }
  }
}

@media (max-width: 1440px) {
  @xl: 14.4vw;

  .banner {
    &-top {
      width: 1034 / @xl;
      height: 468 / @xl;
    }

    &-earth {
      width: 700 / @xl;
      height: 313 / @xl;
    }

    .describe {
      max-width: 1200 / @xl;

      .title-bottom {
        max-width: 650 / @xl;
        font-size: 20 / @xl;
      }

      .btn {
        width: 156 / @xl;
        height: 48 / @xl;
        font-size: 20 / @xl;
      }
    }
  }
}

@media (max-width: 780px) {
  @vv: 7.5vw;

  .banner {
    height: 460 / @vv;
    background:
      url("https://gemini-static.bd.bcebos.com/portal/prod/img/home/banner/mobile_img_banner.webp")
      no-repeat;
    background-size: 100% 100%;

    &-link {
      display: none;
    }

    &-top {
      display: none;
    }

    &-earth {
      display: none;
    }

    .describe {
      max-width: none;
      padding-top: 120 / @vv;
      padding-left: 25 / @vv;

      .title {
        &-top {
          font-size: 28 / @vv;
          line-height: 36 / @vv;
        }

        &-center {
          margin-top: 20 / @vv;
          margin-bottom: 40 / @vv;
          font-size: 44 / @vv;
          line-height: 52 / @vv;
        }

        &-bottom {
          max-width: 620 / @vv;
          margin: 0;
          font-size: 24 / @vv;
        }
      }
    }
  }

  .banner-test-url {
    background:
      url("https://gemini-static.bd.bcebos.com/portal/test/img/home/banner/mobile_img_banner.webp")
      no-repeat;
    background-size: 100% 100%;
  }
}
</style>
