<template>
    <div
      id="ProductAdvantages"
      class="choose-module"
      :class="assetsUrlTest ? 'choose-test-url' : ''"
    >
      <PageWrapper
        class="flex-col hidden animate__animated animate__fadeInUp sl:flex"
      >
        <template slot="content">
          <div class="choose-module-title">趋动云是什么</div>
          <h2 class="choose-module-question">为什么选择趋动云？</h2>
          <div class="flex items-center">
            <div
              v-for="(card, cardIndex) in cardList"
              :key="cardIndex"
              class="card"
              :class="[card.blue ? 'blue' : '', card.position]"
              :style="{
                'background-image': `url(${assetsUrl + card.background})`,
              }"
              @click="cardClick"
            >
              <div class="card-title">{{ card.title }}</div>
              <div class="card-secTitle">{{ card.secTitle }}</div>
              <div v-if="!card.blue" class="card-icon">
                <div v-for="icon in card.iconList" :key="icon.text" class="flex">
                  <div class="flex flex-col items-center">
                    <img
                      :src="assetsUrl + icon.path"
                      :alt="icon.text"
                      loading="lazy"
                      decoding="async"
                    />
                    <div class="card-icon-text">{{ icon.text }}</div>
                  </div>
                  <a-divider type="vertical" class="card-icon-divider" />
                </div>
              </div>
              <div v-else class="card-content">
                <div class="card-content-top">
                  <div
                    v-for="(i, index) in card.content"
                    :key="index"
                    class="flex items-center card-content-top-list"
                  >
                    <img
                      class="card-content-top-icon"
                      :src="`${assetsUrl}/img/home/choose/icon_对勾.svg`"
                      alt="对勾"
                      loading="lazy"
                      decoding="async"
                    />
                    <span class="card-content-top-text">{{ i }}</span>
                  </div>
                </div>
                <div v-if="card.showContentBottom" class="card-content-bottom">
                  <p>* 算力低至 ¥0.49/小时，存储低至 ¥0.1/GB/月</p>
                  <p>注册即送 50GB 免费存储额度</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </PageWrapper>
      <div class="sl:hidden">
        <div class="choose-module-title">Gemini 是什么</div>
        <h2 class="choose-module-question">为什么选择 Gemini？</h2>
        <div class="flex flex-col items-center">
          <div
            v-for="(card, cardIndex) in cardList"
            :key="cardIndex"
            class="card"
            :style="{
              'background-image': `url(${assetsUrl + card.mobileBackground})`,
            }"
          >
            <div class="card-title">{{ card.title }}</div>
            <div class="card-secTitle">{{ card.secTitle }}</div>
            <div class="card-content">
              <div class="card-content-top">
                <div
                  v-for="(i, index) in card.content"
                  :key="index"
                  class="flex items-center card-content-top-list"
                >
                  <img
                    class="card-content-top-icon"
                    :src="`${assetsUrl}/img/home/choose/icon_对勾.svg`"
                    alt="对勾"
                    loading="lazy"
                    decoding="async"
                  />
                  <span class="card-content-top-text">{{ i }}</span>
                </div>
              </div>
              <div v-if="card.showContentBottom" class="card-content-bottom">
                <p>* 算力低至 ¥0.49/小时，存储低至 ¥0.1/GB/月</p>
                <p>注册即送 50GB 免费存储额度</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { handleAssetsUrl } from '@/utils/common.js'
export default {
  name: 'Choose',
  data () {
    return {
      showBlue: true,
      assetsUrl: 'https://gemini-static.bd.bcebos.com/portal/prod',
      assetsUrlTest: false // 使用测试环境bos地址
    }
  },
  computed: {
    cardList () {
      return [
        {
          title: '一站式开发训练平台',
          secTitle: '提升研发效能',
          blue: !this.showBlue,
          background: !this.showBlue
            ? '/img/home/choose/right_blue.webp'
            : '/img/home/choose/right_grey.webp',
          mobileBackground: '/img/home/choose/right_blue.webp',
          position: !this.showBlue ? 'right-position' : '',
          iconList: [
            {
              text: '开箱即用',
              path: '/img/home/choose/icon_开箱即用.svg'
            },
            {
              text: '高效研发',
              path: '/img/home/choose/icon_高效研发.svg'
            },
            {
              text: '数据管理',
              path: '/img/home/choose/icon_数据管理.svg'
            }
          ],
          content: [
            '开箱即用：内置常用开发工具及镜像，缩短环境搭建过程',
            '高效研发：项目制管理开发内容，项目内打通开发训练流程',
            '数据管理：支持外部数据接入及本地数据上传，数据统一管理'
          ],
          showContentBottom: false
        }
      ]
    }
  },
  mounted () {
    this.assetsUrl = handleAssetsUrl()
    this.assetsUrlTest = handleAssetsUrl().includes('/portal/test')
  },
  methods: {
    cardClick () {
      this.showBlue = !this.showBlue
    }
  }
}
</script>

  <style scoped lang="less">
  .choose-module {
    padding-top: 80px;
    background:
      url(https://gemini-static.bd.bcebos.com/portal/prod/img/home/choose/background.webp)
      no-repeat;
    background-color: #f2f5fa;
    background-size: 100% 100%;

    &-title {
      color: #32373f;
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 24px;
      font-weight: 500;
      opacity: 0.5;
    }

    &-question {
      margin-top: 10px;
      margin-bottom: 60px;
      color: #2a2a2a;
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 36px;
      font-weight: 500;
    }

    .card {
      width: 548px;
      height: 360px;
      padding-top: 56px;
      padding-left: 56px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;

      &:hover {
        box-shadow: 0 10px 30px 0 rgba(45, 66, 87, 0.2);
        cursor: pointer;
        transform: scale(101%);
      }

      &-title {
        color: #2a2a2a;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 30px;
        font-weight: 600;
      }

      &-secTitle {
        margin-top: 15px;
        margin-bottom: 56px;
        color: #32373f;
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 24px;
        font-weight: 500;
        opacity: 0.5;
      }

      &-icon {
        display: flex;
        align-items: center;

        .flex:last-of-type {
          .card-icon-divider:last-of-type {
            display: none;
          }
        }

        img {
          width: 50px;
          height: 44px;
        }

        &-text {
          margin-top: 16px;
          color: #8d95a6;
          font-size: 18px;
        }

        &-divider {
          height: 62px;
          margin: 0 50px;
          background: #d2dae5;
        }
      }
    }

    .blue {
      width: 670px;
      height: 430px;
      padding-top: 40px;
      padding-left: 50px;
      border-radius: 16px;
      box-shadow: 12px 10px 59px 0 rgba(54, 67, 81, 0.15) !important;

      &:hover {
        transform: none;
      }

      .card {
        &-title {
          color: #fff;
        }

        &-secTitle {
          margin-top: 16px;
          margin-bottom: 40px;
          color: #f2f5fa;
          opacity: 0.8;
        }

        &-content {
          &-top {
            margin-bottom: 16px;

            &-list {
              margin-bottom: 12px;
            }

            &-icon {
              width: 18px;
              height: 18px;
            }

            &-text {
              margin-left: 10px;
              color: #fff;
              font-size: 20px;
            }
          }

          &-bottom {
            color: #a3cdf6;
            font-size: 18px;
          }
        }
      }
    }

    .left-position {
      z-index: 9;
      margin-right: -18px;
    }

    .right-position {
      z-index: 9;
      margin-left: -18px;
    }
  }

  .choose-test-url {
    background:
      url(https://gemini-static.bd.bcebos.com/portal/test/img/home/choose/background.webp)
      no-repeat;
    background-color: #f2f5fa;
    background-size: 100% 100%;
  }

  @media (max-width: 1440px) {
    @xl: 14.4vw;

    .choose-module {
      height: 782 / @xl;
      padding-top: 80 / @xl;

      &-title {
        font-size: 24 / @xl;
      }

      &-question {
        margin-top: 10 / @xl;
        margin-bottom: 60 / @xl;
        font-size: 36 / @xl;
      }

      .card {
        width: 548 / @xl;
        height: 360 / @xl;
        padding-top: 56 / @xl;
        padding-left: 56 / @xl;

        &:hover {
          box-shadow: 10 / @xl 0 30 / @xl 0 rgba(0, 0, 0, 0.03);
        }

        &-title {
          font-size: 30 / @xl;
        }

        &-secTitle {
          margin-top: 15 / @xl;
          margin-bottom: 56 / @xl;
          font-size: 24 / @xl;
        }

        &-icon {
          img {
            width: 50 / @xl;
            height: 44 / @xl;
          }

          &-text {
            margin-top: 16 / @xl;
            font-size: 18 / @xl;
          }

          &-divider {
            height: 62 / @xl;
            margin: 0 50 / @xl;
          }
        }
      }

      .blue {
        width: 670 / @xl;
        height: 430 / @xl;
        padding-top: 40 / @xl;
        padding-left: 50 / @xl;
        border-radius: 12 / @xl;
        box-shadow: 12 / @xl 10 / @xl 59 / @xl 0 rgba(54, 67, 81, 0.15) !important;

        .card {
          &-secTitle {
            margin-top: 16 / @xl;
            margin-bottom: 40 / @xl;
          }

          &-content {
            &-top {
              margin-bottom: 16 / @xl;

              &-icon {
                width: 18 / @xl;
                height: 18 / @xl;
              }

              &-list {
                margin-bottom: 12 / @xl;
              }

              &-text {
                margin-left: 10 / @xl;
                font-size: 20 / @xl;
              }
            }

            &-bottom {
              font-size: 18 / @xl;
            }
          }
        }
      }

      .left-position {
        margin-right: -18 / @xl;
      }

      .right-position {
        margin-left: -18 / @xl;
      }
    }
  }

  @media (max-width: 780px) {
    @vv: 7.5vw;

    .choose-module {
      height: 760 / @vv;
      padding: 40 / @vv;
      background:
        url(https://gemini-static.bd.bcebos.com/portal/prod/img/home/choose/mobile_background.webp)
        no-repeat;
      background-size: 100% 100%;

      &-title {
        font-size: 24 / @vv;
      }

      &-question {
        margin-top: 10 / @vv;
        margin-bottom: 40 / @vv;
        font-size: 32 / @vv;
      }

      .card {
        width: 670 / @vv;
        height: 430 / @vv;
        padding-top: 40 / @vv;
        padding-left: 50 / @vv;
        margin-bottom: 40 / @vv;
        pointer-events: none;

        &-title {
          color: #fff;
          font-size: 30 / @vv;
        }

        &-secTitle {
          margin-top: 16 / @vv;
          margin-bottom: 40 / @vv;
          color: #f2f5fa;
          font-size: 24 / @vv;
          opacity: 0.8;
        }

        &-content {
          &-top {
            margin-bottom: 16 / @vv;

            &-list {
              margin-bottom: 12 / @vv;
            }

            &-icon {
              width: 18 / @vv;
              height: 18 / @vv;
            }

            &-text {
              margin-left: 10 / @vv;
              color: #fff;
              font-size: 20 / @vv;
            }
          }

          &-bottom {
            color: #a3cdf6;
            font-size: 20 / @vv;
          }
        }
      }
    }

    .choose-test-url {
      background:
        url(https://gemini-static.bd.bcebos.com/portal/test/img/home/choose/mobile_background.webp)
        no-repeat;
      background-size: 100% 100%;
    }
  }
  </style>
